import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAnalyticsCounters,
  fetchAnalyticsCountersCSV,
  fetchAnalyticsUsers,
  fetchAnalyticsUsersCSV,
  fetchGames,
  fetchGamesCSV,
} from '../../../../redux/actions/Analytics';

export const useDataFetching = (filters, dataType) => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector(state => state.analytics);
  const [availableCountries, setAvailableCountries] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      const { startDate, endDate } = getDateRange(filters);
      const { externalType } = filters;

      if (!externalType && externalType !== null) {
        console.log('No valid externalType, skipping fetch.');
        return;
      }

      switch (dataType) {
        case 'counters':
          dispatch(fetchAnalyticsCounters({ externalType, startDate, endDate }));
          break;
        case 'users':
          dispatch(fetchAnalyticsUsers({ externalType, startDate, endDate }));
          break;
        case 'games':
          dispatch(fetchGames({ externalType, startDate, endDate }));
          break;
        default:
          console.log('Invalid dataType');
      }
    };

    console.log('Triggering data fetch with filters:', filters);
    fetchData();
  }, [dispatch, filters, dataType]);

  const handleDownloadCSV = () => {
    switch (dataType) {
      case 'counters':
        dispatch(fetchAnalyticsCountersCSV());
        break;
      case 'users':
        dispatch(fetchAnalyticsUsersCSV());
        break;
      case 'games':
        dispatch(fetchGamesCSV());
        break;
      default:
        console.log('Invalid dataType for CSV download');
    }
  };

  useEffect(() => {
    if (users && users.length > 0) {
      const countriesSet = new Set(users.map(user => user.country));
      setAvailableCountries(Array.from(countriesSet));
    }
  }, [users]);

  const getDateRange = filters => {
    let startDate, endDate;
    const today = new Date();

    if (filters.dateType === 'day') {
      const localDate = new Date(filters.selectedDate || new Date());

      localDate.setHours(0, 0, 0, 0);
      startDate = localDate.toISOString();

      localDate.setHours(23, 59, 59, 999);
      endDate = localDate.toISOString();
    } else if (filters.dateType === 'week') {
      const start = new Date(filters.startDate || new Date());

      start.setHours(0, 0, 0, 0);
      startDate = start.toISOString();

      const end = new Date(start);
      end.setDate(end.getDate() + 6);
      end.setHours(23, 59, 59, 999);

      if (end > today) {
        end.setTime(today.getTime());
        end.setHours(23, 59, 59, 999);
      }
      endDate = end.toISOString();
    } else if (filters.dateType === 'month' && filters.selectedMonth) {
      const monthStart = new Date(filters.selectedMonth.getFullYear(), filters.selectedMonth.getMonth(), 1);
      monthStart.setHours(0, 0, 0, 0);
      startDate = monthStart.toISOString();

      const monthEnd = new Date(filters.selectedMonth.getFullYear(), filters.selectedMonth.getMonth() + 1, 0);
      monthEnd.setHours(23, 59, 59, 999);
      endDate = monthEnd.toISOString();
    } else if (filters.dateType === 'month' && !filters.selectedMonth) {
      console.log('No selected month provided');
      return { startDate: null, endDate: null };
    }

    console.log('Calculated date range:', { startDate, endDate });

    return { startDate, endDate };
  };

  return {
    users,
    loading,
    error,
    handleDownloadCSV,
    availableCountries,
  };
};
