import {
  FETCH_START,
  FETCH_ANALYTICS_COUNTERS_SUCCESS,
  FETCH_ANALYTICS_COUNTERS_FAILURE,
  FETCH_ANALYTICS_COUNTERS_CSV_SUCCESS,
  FETCH_ANALYTICS_COUNTERS_CSV_FAILURE,
  FETCH_ANALYTICS_USERS_SUCCESS,
  FETCH_ANALYTICS_USERS_FAILURE,
  FETCH_ANALYTICS_USERS_CSV_SUCCESS,
  FETCH_ANALYTICS_USERS_CSV_FAILURE,
  FETCH_GAMES_SUCCESS,
  FETCH_GAMES_FAILURE,
  FETCH_GAMES_CSV_SUCCESS,
  FETCH_GAMES_CSV_FAILURE,
  FETCH_GAME_USERS_SUCCESS,
  FETCH_GAME_USERS_FAILURE,
  FETCH_GAME_USERS_CSV_SUCCESS,
  FETCH_GAME_USERS_CSV_FAILURE,
  CLEAR_ANALYTICS_DATA,
  FETCH_NEW_USERS_THIS_WEEK_SUCCESS,
  FETCH_NEW_USERS_THIS_WEEK_FAILURE,
  SET_GLOBAL_USER_TYPE,
} from '../actions/Analytics';

const INIT_STATE = {
  counters: null,
  users: {},
  csvData: null,
  usersCsvData: null,
  games: [],
  gameUsers: {},
  newUsersThisWeek: 0,
  gamesCsvData: null,
  gameUsersCsvData: null,
  loading: false,
  error: null,
  globalUserType: null,
};

const handleSuccess = (state, action, key) => ({
  ...state,
  [key]: action.payload,
  loading: false,
  error: null,
});

const handleFailure = (state, action) => ({
  ...state,
  error: action.payload,
  loading: false,
});

const analyticsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_ANALYTICS_DATA:
      return INIT_STATE;
    case FETCH_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // Handling Analytics Counters
    case FETCH_ANALYTICS_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_ANALYTICS_COUNTERS_FAILURE:
      return handleFailure(state, action);
    case FETCH_ANALYTICS_COUNTERS_CSV_SUCCESS:
      return handleSuccess(state, action, 'csvData');
    case FETCH_ANALYTICS_COUNTERS_CSV_FAILURE:
      return handleFailure(state, action);

    // Handling Analytics Users
    case FETCH_ANALYTICS_USERS_SUCCESS:
      return handleSuccess(state, action, 'users');
    case FETCH_ANALYTICS_USERS_FAILURE:
      return handleFailure(state, action);
    case FETCH_ANALYTICS_USERS_CSV_SUCCESS:
      return handleSuccess(state, action, 'usersCsvData');
    case FETCH_ANALYTICS_USERS_CSV_FAILURE:
      return handleFailure(state, action);

    // Handling Games
    case FETCH_GAMES_SUCCESS:
      return handleSuccess(state, action, 'games');
    case FETCH_GAMES_FAILURE:
      return handleFailure(state, action);
    case FETCH_GAMES_CSV_SUCCESS:
      return handleSuccess(state, action, 'gamesCsvData');
    case FETCH_GAMES_CSV_FAILURE:
      return handleFailure(state, action);

    // Handling Game Users
    case FETCH_GAME_USERS_SUCCESS:
      return {
        ...state,
        gameUsers: {
          ...state.gameUsers,
          [action.payload.gameId]: action.payload.users,
        },
        loading: false,
        error: null,
      };
    case FETCH_GAME_USERS_FAILURE:
      return handleFailure(state, action);
    case FETCH_GAME_USERS_CSV_SUCCESS:
      return handleSuccess(state, action, 'gameUsersCsvData');
    case FETCH_GAME_USERS_CSV_FAILURE:
      return handleFailure(state, action);

    case FETCH_NEW_USERS_THIS_WEEK_SUCCESS:
      return {
        ...state,
        newUsersThisWeek: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_NEW_USERS_THIS_WEEK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_GLOBAL_USER_TYPE:
      return {
        ...state,
        globalUserType: action.payload,
      };

    // Default case
    default:
      return state;
  }
};

export default analyticsReducer;
