import { useState } from 'react';

const DEFAULT_FILTERS = {
  country: 'ALL',
  dateType: null,
  selectedDate: null,
  startDate: null,
  endDate: null,
  selectedMonth: null,
  filterOpen: false,
  searchQuery: '',
};

export const useFilters = () => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handleFilterChange = (filter, value) => {
    const today = new Date();

    if (filter === 'startDate' && filters.dateType === 'week') {
      const startDate = new Date(value);
      let endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);

      if (endDate > today) {
        endDate = today;
      }

      setFilters(prevFilters => ({
        ...prevFilters,
        startDate: startDate,
        endDate: endDate,
      }));
    } else {
      setFilters(prevFilters => ({ ...prevFilters, [filter]: value }));
    }
  };

  const handleFilterOpen = () => handleFilterChange('filterOpen', true);

  const handleFilterClose = () => handleFilterChange('filterOpen', false);

  const handleApplyFilter = () => {
    handleFilterChange('filterOpen', false);
  };

  const handleReset = () => {
    setFilters(DEFAULT_FILTERS);
  };

  return {
    filters,
    handleFilterChange,
    handleFilterOpen,
    handleFilterClose,
    handleApplyFilter,
    handleReset,
  };
};
