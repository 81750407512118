import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '24px 0',
    minWidth: 120,
    width: '100%',
  },
  dialogContent: {
    padding: '32px',
    position: 'relative',
  },
  dialogActions: {
    padding: '24px',
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
  },
  gridContainer: {
    marginBottom: '24px',
  },
  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '16px',
  },
  resetButton: {
    marginRight: 'auto',
  },
}));

const UserFilterModal = ({ open, onClose, handleApply, filters, handleFilterChange, availableCountries, onReset }) => {
  const classes = useStyles();
  const today = new Date();

  const handleResetFilters = () => {
    onReset();
  };

  const handleStartDateChange = date => {
    const startDate = new Date(date);
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    if (endDate > today) {
      endDate = today;
    }

    handleFilterChange('startDate', startDate);
    handleFilterChange('endDate', endDate);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <div className={classes.dialogTitleContainer}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon color="primary" />
          </IconButton>
          <Typography variant="h2" style={{ fontWeight: 'bold', top: '60px', position: 'relative' }}>
            User Filter Options
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormControl className={classes.formControl}>
          <InputLabel>Country</InputLabel>
          <Select value={filters.country} onChange={e => handleFilterChange('country', e.target.value)}>
            <MenuItem value="ALL">All Countries</MenuItem>
            {availableCountries
              .filter(country => country !== 'ALL')
              .map(country => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel>Date Type</InputLabel>
          <Select value={filters.dateType} onChange={e => handleFilterChange('dateType', e.target.value)}>
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </FormControl>
        {filters.dateType === 'day' && (
          <DatePicker
            label="Select Date"
            value={filters.selectedDate}
            onChange={date => handleFilterChange('selectedDate', date)}
            className={classes.formControl}
            fullWidth
            maxDate={today}
          />
        )}
        {filters.dateType === 'week' && (
          <React.Fragment>
            <DatePicker
              label="Start Date"
              value={filters.startDate}
              onChange={handleStartDateChange}
              className={classes.formControl}
              fullWidth
              maxDate={today}
            />
            <DatePicker
              label="End Date"
              value={filters.endDate}
              disabled
              className={classes.formControl}
              fullWidth
              maxDate={today}
            />
          </React.Fragment>
        )}
        {filters.dateType === 'month' && (
          <DatePicker
            label="Select Month"
            views={['year', 'month']}
            value={filters.selectedMonth}
            onChange={date => handleFilterChange('selectedMonth', date)}
            className={classes.formControl}
            fullWidth
            maxDate={today}
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleResetFilters} color="primary" variant="contained" className={classes.resetButton}>
          Reset
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary" variant="contained">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserFilterModal;
