import React from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 16,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    textAlign: 'center',
    fontWeight: 700,
    '&:first-child': {
      textAlign: 'left',
      paddingLeft: 24,
    },
  },
}));

const TableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.tableCellRoot}>Email</TableCell>
      <TableCell className={classes.tableCellRoot}>Nickname</TableCell>
      <TableCell className={classes.tableCellRoot}>Country</TableCell>
      <TableCell className={classes.tableCellRoot}>Total Points</TableCell>
      <TableCell className={classes.tableCellRoot}>Total Bets</TableCell>
      <TableCell className={classes.tableCellRoot}>Most Recent Bet Date</TableCell>
      <TableCell className={classes.tableCellRoot}>Activity Status</TableCell>
    </TableRow>
  );
};

export default TableHeading;
